<template>
	<!--서비스 상세 페이지 - 백신-->
	<div class="Cycontainer" v-if="computedValue === `ko`">
		<!--content1-->
		<div id="content1">
			<div class="servicecontent__wrapper--row bg">
				<div class="servicecontent__header">
					<h2 v-scrollanimation class="header_h2">Malware로 부터 안전하게</h2>
					<h1 v-scrollanimation>FC-Anti Virus</h1>
					<p>
						자사의 차별화된 백신 FC-Anti Virus로<br />개인 업무 환경에 맞추어
						분석하고<br />가장 적합한 백신 서비스를 추천하고 제공합니다.<br />
						다양한 유형의 Malware를 미리 분석하고 치료하며<br />
						자사의 독자적 기술로 신종 · 변종 Malware를 탐지합니다.<br />기관 및
						기업에서 사용하는 PC를 보호하세요.
					</p>
					<div class="servicecontent__btn">
						<router-link to="/inquiry">
							<button class="btn--md btn--fill-accent">지금 문의하기</button>
						</router-link>
						<router-link to="/solutionvaccine">
							<button class="btn--md btn--fill-gray">솔루션 바로가기</button>
						</router-link>
					</div>
				</div>
				<div class="servicecontent__mainimg">
					<img :src="vaccinemain" />
				</div>
			</div>
		</div>
		<!--content1 end-->
		<!--contnet2-->
		<div id="content2">
			<div class="servicecontent__wrapper--imgbg">
				<img :src="vaccineBg" />
				<div class="servicecontent__textbox">
					<h1 v-scrollanimation>클라이언트의 사용 환경을 고려한</h1>
					<div class="servicecontent_stroke"></div>
					<h2 v-scrollanimation>백신 보안 서비스</h2>
					<p v-scrollanimation>
						Malware 탐지 및 치료<br />
						자사의 독자적 기술로 신종 · 변종 Malware를 탐지합니다.
					</p>
				</div>
			</div>
			<div class="servicecontent2_box">
				<div class="servicecontent2_textbox">
					<h1>
						간단한 API호출로<br />탐지부터 치료까지 해결하는
						<span>Anti-Virus</span>
					</h1>
					<div class="border-graient"></div>
					<p>
						ML 기법 활용과 독자적으로 개발한 유사도 알고리즘을 통해<br />웜(Worm),
						트로이목마(Trojan), 랜섬웨어(Ransomeware) 등<br />기존에 알려지지
						않은 Malware를 탐지 및 치료할 수 있는 <br />백신 Anti-Virus입니다.
					</p>
				</div>
			</div>
		</div>
		<!--content3-->
		<div id="content3">
			<div class="content3-title_bg"></div>
			<div class="content3-title">
				<h1>기업 환경을 더 안전하게</h1>
				<p class="servicevaccine-content3">
					개인 정보가 중요한 의료 및 금융기관의 정보들을<br />
					백신 서비스를 이용해 미리 탐지하여 예방해 보세요!
				</p>
			</div>
			<div class="content3_box">
				<img :src="content3img" />
			</div>
		</div>
		<!--content3 end-->
		<div id="content4">
			<div class="content4-title">
				<p>Vaccine Detail Service</p>
				<h2>백신 상세 서비스</h2>
			</div>

			<div class="content4_box">
				<div class="content_box">
					<div class="img_box1"><img :src="pe" /></div>
					<div class="text_box">
						<h2>PE</h2>
						<h3>Malware Detecting Model</h3>
						<div class="border"></div>
						<p>
							Windows 운영체제에서 사용되는 실행 파일 형식인 PE ( portable
							exevutable )<br />File Format의 Header영역의 data에서 뽑아낸
							특징을 바탕으로<br />
							Malware의 유무를 판단 및 탐지하는 서비스입니다.
						</p>
					</div>
				</div>

				<div class="content_box content_box2">
					<div class="text_box-right">
						<h2>N-gram</h2>
						<h3>Malware Detecting Model</h3>
						<div class="border"></div>
						<p>
							파일 데이터 영역 안에서 프로그램의 실제 코드가 위치한 부분을
							찾아<br />
							코드 영역에 해당하는 data를 N-gram 분석 기법을 이용해 특징을
							추출하고,<br />
							이를 바탕으로 Malware의 유무를 판단 및 탐지하는 서비스입니다.
						</p>
					</div>
					<div class="img_box2"><img :src="ngram" /></div>
				</div>

				<div class="content_box">
					<div class="img_box1"><img :src="image" /></div>

					<div class="text_box">
						<h2>Image</h2>
						<h3>Malware Detecting Model</h3>
						<div class="border"></div>
						<p>
							프로그램 파일에 대한 바이너리(binary)를 이미지로 변환하고,<br />변환된
							이미지 data에서 뽑아낸 특징을 바탕으로 여러 가지 File에 대한<br />
							악성코드 유무를 판단 및 탐지하는 서비스입니다.
						</p>
					</div>
				</div>
				<div class="content_box content_box2">
					<div class="text_box-right">
						<h2>FC-Anti Virus</h2>
						<h3>Vaccine</h3>
						<div class="border"></div>
						<p>
							PE Malware Detecting Model, N-gram Malware Detecting Model
							<br />
							Image Malware Detecting Model을 적용하여<br />
							신종 또는 변종 악성코드를 탐지하고, 이를 치료하는 백신
							서비스입니다
						</p>
					</div>
					<div class="img_box2"><img :src="ngram" /></div>
				</div>
			</div>
		</div>

		<!--content4 end-->

		<!--content5-->
		<div id="content5Cy">
			<!--content5 title-->
			<div class="content5cy-title">
				<p>Utilization by industry</p>
				<h2>산업별 활용 사례</h2>
			</div>
			<!--content5_box-->
			<div class="content5_box">
				<!--box1-->
				<div class="box1">
					<div class="box_title">
						<h1>01</h1>
						<div class="circle"></div>
					</div>
					<div class="box_subtitle">
						<h2>의료기관</h2>
						<p>Malware 탐지를 통한<br />의료 데이터 유출 방지</p>
					</div>
				</div>
				<!--box1 end-->
				<!--box2-->
				<div class="box2">
					<div class="box_title">
						<h1>02</h1>
						<div class="circle"></div>
					</div>
					<div class="box_subtitle">
						<h2>분석기관</h2>
						<p>Malware 탐지를 통한<br />기업 PC 보호</p>
					</div>
				</div>
				<!--box2 end-->
				<!--box3-->
				<div class="box3">
					<div class="box_title">
						<h1>03</h1>
						<div class="circle"></div>
					</div>
					<div class="box_subtitle">
						<h2>금융기관</h2>
						<p>Malware 탐지를 통한<br />고객 정보 보호</p>
					</div>
				</div>
			</div>
			<!--box3 end-->
			<!--content5_box end-->
		</div>
		<!--content5 end-->
		<!--content6-->
		<div id="content6">
			<SwiperKr />
		</div>
		<!--content6 end-->
		<!--content7-->
		<!-- <div class="servicecontent__wrapper center bg contents7">
			<h1>업무 개발 환경에 맞춰 추천 하는 서비스!</h1>
			<p>
				필요한 제품을 확인하고 개발 환경에 맞추어 추천 하는 서비스 입니다!<br />
				지금 바로 진단페이지를 통해 제품을 받아보세요!
			</p>
			<div>
				<button class="btn--md btn--fill-accent">
					<router-link to="/warning">진단 페이지</router-link></button
				><button class="btn--md btn--fill-gray">
					<router-link to="/inquiry">문의 하기</router-link>
				</button>
			</div>
		</div> -->

		<div class="mb__Cont4">
			<div class="TopText_mb">요금이 궁금하신가요?</div>
			<div class="BtmText_mb">내 비지니스 유형에 적합한 플랜을 찾아보세요!</div>
			<router-link to="/pricingsecurity" class="mb-price-button">
				모듈러스 요금 확인
			</router-link>
			<router-link to="/pricingbimining" class="mb-price-button">
				BI Mining 요금 확인
			</router-link>
			<div class="TopText_mb">솔루션이 궁금하신가요?</div>
			<div class="BtmText_mb">
				내 비지니스 유형에 적합한 솔루션을 찾아보세요!
			</div>
			<router-link to="/solutioncrypto" class="mb-solution-button">
				암호스키마 솔루션 바로가기
			</router-link>
			<router-link to="/solutionvaccine" class="mb-solution-button">
				FC-Anti Virus 솔루션 바로가기
			</router-link>
			<router-link to="/" class="mb-solution-button">
				CryptaLedger Tech<br />
				솔루션 바로가기
			</router-link>
		</div>
	</div>
	<div class="Cycontainer" v-else-if="computedValue === `en`">
		<!--content1-->
		<div id="content1">
			<div class="servicecontent__wrapper--row bg">
				<div class="servicecontent__header">
					<h2 v-scrollanimation class="header_h2">
						Safely Protected from Malware with FC-Anti Virus
					</h2>
					<h1 v-scrollanimation>FC-Anti Virus</h1>
					<p>
						Our specialized FC-Anti Virus analyzes and adapts to individual work
						environments<br />to provide the most suitable antivirus service. It
						detects, analyzes, and treats various<br />types of malware,
						utilizing proprietary technology to identify and block both known<br />
						and unknown threats. Protect your organization's PCs against malware
						with a solution trusted<br />
						by many institutions and companies.
					</p>
					<div class="servicecontent__btn">
						<router-link to="/inquiry">
							<button class="btn--md btn--fill-accent">Contact Us</button>
						</router-link>
						<router-link to="/solutionvaccine">
							<button class="btn--md btn--fill-gray">Go to Solution</button>
						</router-link>
					</div>
				</div>
				<div class="servicecontent__mainimg">
					<img :src="vaccinemain" />
				</div>
			</div>
		</div>
		<!--content1 end-->
		<!--contnet2-->
		<div id="content2">
			<div class="servicecontent__wrapper--imgbg">
				<img :src="vaccineBg" />
				<div class="servicecontent__textbox">
					<h1 v-scrollanimation>Tailored for the Client's Usage Environment</h1>
					<div class="servicecontent_stroke"></div>
					<h2 v-scrollanimation>Antivirus Security Service</h2>
					<p v-scrollanimation>
						Detecting and Treating Malware Our proprietary technology
						identifies<br />
						and blocks both known and unknown malware threats.
					</p>
				</div>
			</div>
			<div class="servicecontent2_box">
				<div class="servicecontent2_textbox">
					<h1>
						Solving Everything from Detection to Treatment<br />with Simple API
						Calls,
						<span>Anti-Virus</span>
					</h1>
					<div class="border-graient"></div>
					<p>
						This antivirus solution uses machine learning and<br />proprietary
						algorithms to detect and treat malware,<br />including worms,
						trojans, ransomware,<br />and other unknown threats. It can identify
						and address malware<br />that is not recognized by conventional
						standards.
					</p>
				</div>
			</div>
		</div>
		<!--content3-->
		<div id="content3">
			<div class="content3-title_bg"></div>
			<div class="content3-title">
				<h1>Enhance Corporate Security</h1>
				<p class="servicevaccine-content3">
					Use our antivirus service to proactively detect and prevent threats to
					protect sensitive personal<br />
					information in healthcare and financial institutions!
				</p>
			</div>
			<div class="content3_box">
				<img :src="content3imgen" />
			</div>
		</div>
		<!--content3 end-->
		<div id="content4">
			<div class="content4-title">
				<h2>Vaccine Detail Service</h2>
			</div>

			<div class="content4_box">
				<div class="content_box">
					<div class="img_box1"><img :src="pe" /></div>
					<div class="text_box">
						<h2>PE</h2>
						<h3>Malware Detecting Model</h3>
						<div class="border"></div>
						<p>
							This service determines and detects the presence of malware based
							on specific characteristics<br />hextracted from the data in the
							Header section of the File Format for the executable file format
							<br />
							PE (Portable Executable), used in the Windows operating system.
						</p>
					</div>
				</div>

				<div class="content_box content_box2">
					<div class="text_box-right">
						<h2>N-gram</h2>
						<h3>Malware Detecting Model</h3>
						<div class="border"></div>
						<p>
							This service identifies and detects the presence of malware by
							locating the actual code section<br />
							of the program within the file data area, extracting
							characteristics from the data in the code<br />
							section using an N-gram analysis method.
						</p>
					</div>
					<div class="img_box2"><img :src="ngram" /></div>
				</div>

				<div class="content_box">
					<div class="img_box1"><img :src="image" /></div>

					<div class="text_box">
						<h2>Image</h2>
						<h3>Malware Detecting Model</h3>
						<div class="border"></div>
						<p>
							This service determines and detects the presence of malicious code
							in various files by converting<br />hthe binary of a program file
							into an image, extracting features from the converted image
							data,<br />
							and using them as a basis.
						</p>
					</div>
				</div>
				<div class="content_box content_box2">
					<div class="text_box-right">
						<h2>FC-Anti Virus</h2>
						<h3>Vaccine</h3>
						<div class="border"></div>
						<p>
							This antivirus service applies the PE Malware Detecting Model,
							<br />
							N-gram Malware Detecting Model, and Image Malware Detecting
							Model<br />
							to detect and treat new or variant malicious codes.
						</p>
					</div>
					<div class="img_box2"><img :src="ngram" /></div>
				</div>
			</div>
		</div>

		<!--content4 end-->

		<!--content5-->
		<div id="content5Cy">
			<!--content5 title-->
			<div class="content5cy-title">
				<h2>Utilization by industry</h2>
			</div>
			<!--content5_box-->
			<div class="content5_box">
				<!--box1-->
				<div class="box1">
					<div class="box_title">
						<h1>01</h1>
						<div class="circle"></div>
					</div>
					<div class="box_subtitle">
						<h2>Medical Institutions</h2>
						<p>Prevent leakage of medical data through malware detection</p>
					</div>
				</div>
				<!--box1 end-->
				<!--box2-->
				<div class="box2">
					<div class="box_title">
						<h1>02</h1>
						<div class="circle"></div>
					</div>
					<div class="box_subtitle">
						<h2>Analysis Institutions</h2>
						<p>Protect corporate PCs through malware detection</p>
					</div>
				</div>
				<!--box2 end-->
				<!--box3-->
				<div class="box3">
					<div class="box_title">
						<h1>03</h1>
						<div class="circle"></div>
					</div>
					<div class="box_subtitle">
						<h2>Financial Institutions</h2>
						<p>Protect customer information through malware detection</p>
					</div>
				</div>
			</div>
			<!--box3 end-->
			<!--content5_box end-->
		</div>
		<!--content5 end-->
		<!--content6-->
		<div id="content6">
			<SwiperKr />
		</div>
		<!--content6 end-->
		<!--content7-->
		<!-- <div class="servicecontent__wrapper center bg contents7">
			<h1>업무 개발 환경에 맞춰 추천 하는 서비스!</h1>
			<p>
				필요한 제품을 확인하고 개발 환경에 맞추어 추천 하는 서비스 입니다!<br />
				지금 바로 진단페이지를 통해 제품을 받아보세요!
			</p>
			<div>
				<button class="btn--md btn--fill-accent">
					<router-link to="/warning">진단 페이지</router-link></button
				><button class="btn--md btn--fill-gray">
					<router-link to="/inquiry">문의 하기</router-link>
				</button>
			</div>
		</div> -->

		<div class="mb__Cont4">
			<div class="TopText_mb">Curious about the pricing?</div>
			<div class="BtmText_mb">
				Find the plan that's right for your business type!
			</div>
			<router-link to="/pricingsecurity" class="mb-price-button">
				Modulus Check Pricing
			</router-link>
			<router-link to="/pricingbimining" class="mb-price-button">
				BI Mining Check Pricing
			</router-link>
			<div class="TopText_mb">Curious about the solution?</div>
			<div class="BtmText_mb">
				Find the plan that's right for your business type!
			</div>
			<router-link to="/solutioncrypto" class="mb-solution-button">
				Crypto Schema Go to Solution
			</router-link>
			<router-link to="/solutionvaccine" class="mb-solution-button">
				FC-Anti Virus Go to Solution
			</router-link>
			<router-link to="/" class="mb-solution-button">
				CryptaLedger Tech <br />Go to Solution
			</router-link>
		</div>
	</div>
</template>

<script>
import fcanti from '../assets/images/service/fcanti.svg';
import vaccineBg from '../assets/images/service/vaccinebg.jpg';
import speciality4 from '../assets/images/service/speciality4.svg';
import speciality5 from '../assets/images/service/speciality5.svg';
import speciality6 from '../assets/images/service/speciality6.svg';
import vaccinemain from '../assets/images/service/vaccinemain.svg';
import content3img from '../assets/images/service/servicevaccine-content3.svg';
import content3imgen from '../assets/images/service/servicevaccine-content3en.svg';
import pe from '../assets/images/service/vaccine-pe.svg';
import ngram from '../assets/images/service/vaccine-n-gram.svg';
import image from '../assets/images/service/vaccine-image.svg';
import anti from '../assets/images/service/vaccine-fc-antivirus.svg';

export default {
	data() {
		return {
			service: 1,
			fcanti,
			vaccineBg,
			speciality4,
			speciality5,
			speciality6,
			vaccinemain,
			content3img,
			content3imgen,
			pe,
			ngram,
			image,
			anti,
		};
	},
	computed: {
		computedValue() {
			return this.$store.state.language;
		},
	},
	methods: {
		showService1() {
			this.service = 1;
		},
		showService2() {
			this.service = 2;
		},
		showService3() {
			this.service = 3;
		},
		showService4() {
			this.service = 4;
		},
		showService5() {
			this.service = 5;
		},
	},
};
</script>

<style></style>
